import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Drawer, Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/People';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import SettingsIcon from '@mui/icons-material/Settings';
import { useAuth } from '../auth/useAuth';
import { useNavigate } from 'react-router-dom';

const AdminSidebar = ({ isOpen, onClose }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();


  const menuItems = [
    { text: 'ניהול משתמשים', icon: <PeopleIcon />, path: '/admin/users' },
    { text: 'ניהול הזמנות', icon: <ReceiptIcon />, path: '/admin/tourists' },
    { text: 'ניהול תיקופים', icon: <ConfirmationNumberIcon />, path: '/admin/passenger-dashboard' },
    { text: 'ניהול אוטובוסים', icon: <DirectionsBusIcon />, path: '/admin/buses' },
    { text: 'הגדרות מערכת', icon: <SettingsIcon />, path: '/admin/settings' },
  ];

  return (
    <Drawer
      variant="temporary"
      open={isOpen}  // נשאר פתוח כאשר state == true
      onClose={onClose}  // סוגר את הסיידבר רק כאשר נדרש
      ModalProps={{
        keepMounted: true, // מאפשר סגירה נכונה עם פתיחה מהירה
      }}
      sx={{
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
          top: '64px', // גובה ה-AppBar
          height: 'calc(100% - 64px)',
        },
      }}
    >
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {menuItems.map((item) => (
            <ListItem button key={item.text} component={Link} to={item.path} onClick={onClose}>
              <ListItemIcon sx={{ minWidth: 'auto', marginLeft: 2 }}>{item.icon}</ListItemIcon>
              <ListItemText primary={<Typography component="span" sx={{ textAlign: 'right' }}>{item.text}</Typography>} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default AdminSidebar;
