import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Typography,
  TextField,
  Grid,
  Paper,
  Box,
  CircularProgress,
  Button,
  Chip,
  Snackbar,
  Alert
} from '@mui/material';
import { useAuth } from '../auth/useAuth';
import { settingsConfig, getSettingsByCategory } from '../config/settingsConfig';

const Settings = () => {
  const { api } = useAuth();
  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const settingsByCategory = getSettingsByCategory();

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      setLoading(true);
      const response = await api.get('/settings');
      const settingsData = response.data.reduce((acc, setting) => {
        acc[setting.key] = setting;
        return acc;
      }, {});
      setSettings(settingsData);
    } catch (error) {
      console.error('Failed to fetch settings', error);
      setErrorMessage('טעינת ההגדרות נכשלה. אנא נסה שנית.');
    } finally {
      setLoading(false);
    }
  };

  const updateTravelMode = (changeDateValue) => {
    if (!changeDateValue) return;

    const today = new Date();
    const changeDateObj = new Date(changeDateValue);
    today.setHours(0, 0, 0, 0);
    changeDateObj.setHours(0, 0, 0, 0);
    
    const newTravelMode = today < changeDateObj ? 'הלוך' : 'חזור';
    
    console.log('Updating travel mode:', { 
      today: today.toISOString(), 
      changeDate: changeDateObj.toISOString(), 
      newTravelMode 
    });

    setSettings(prev => ({
      ...prev,
      travel_mode: { ...prev.travel_mode, value: newTravelMode },
    }));
    
    setHasChanges(true);
  };

  const handleUpdate = (key, newValue) => {
    console.log('Handling update:', { key, newValue });
    
    if (key === 'travel_direction_change_date') {
      updateTravelMode(newValue);
    }
    
    setSettings(prev => ({
      ...prev,
      [key]: { ...prev[key], value: newValue }
    }));
    
    setHasChanges(true);
  };

  const saveAllChanges = async () => {
    try {
      setLoading(true);
      for (const [key, setting] of Object.entries(settings)) {
        await api.put(`/settings/${key}`, {
          display_name: setting.display_name,
          key: key,
          value: setting.value,
          value_type: setting.value_type
        });
      }
      setHasChanges(false);
      setShowSuccessMessage(true);
    } catch (error) {
      console.error('Failed to save settings', error);
      setErrorMessage('אירעה שגיאה בשמירת ההגדרות. אנא נסה שנית.');
    } finally {
      setLoading(false);
    }
  };

  const syncDestinations = useCallback(async () => {
    try {
      setLoading(true);
      await api.post('/settings/sync-destinations');
      await fetchSettings(); // Refetch settings after sync
      setShowSuccessMessage(true);
    } catch (error) {
      console.error('Failed to sync destinations', error);
      setErrorMessage('סנכרון היעדים נכשל. אנא נסה שנית.');
    } finally {
      setLoading(false);
    }
  }, [api, fetchSettings]);

  const renderSettingInput = (setting) => {
    const value = settings[setting.key] ? settings[setting.key].value : '';
  
    switch (setting.key) {  
      case 'travel_mode':
        return (
          <TextField
            fullWidth
            value={value}
            disabled={true}
            InputProps={{
              readOnly: true,
            }}
            label={setting.displayName}
          />
        );
      case 'travel_direction_change_date':
        return (
          <TextField
            fullWidth
            type="date"
            value={value}
            onChange={(e) => handleUpdate(setting.key, e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            label={setting.displayName}
          />
        );
      case 'departure_destinations':
      case 'return_destinations':
        return (
          <Box>
            {(value || []).map((destination, index) => (
              <Chip
                key={index}
                label={destination}
                sx={{ m: 0.5 }}
              />
            ))}
          </Box>
        );
      default:
        return (
          <TextField
            fullWidth
            value={value}
            onChange={(e) => handleUpdate(setting.key, e.target.value)}
            label={setting.displayName}
          />
        );
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom align="center">הגדרות מערכת</Typography>
      <Grid container spacing={3}>
        {Object.entries(settingsByCategory).map(([category, categorySettings]) => (
          <Grid item xs={12} key={category}>
            <Typography variant="h5" gutterBottom>{category}</Typography>
            <Grid container spacing={3}>
              {categorySettings.map((setting) => (
                <Grid item xs={12} md={6} key={setting.key}>
                  <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
                    <Typography variant="h6" gutterBottom>{setting.displayName}</Typography>
                    {renderSettingInput(setting)}
                  </Paper>
                </Grid>
              ))}
            </Grid>
            {category === 'יעדים' && (
              <Box mt={2} display="flex" justifyContent="center">
                <Button 
                  variant="contained" 
                  color="secondary" 
                  onClick={syncDestinations}
                  disabled={loading}
                >
                  סנכרן יעדים
                </Button>
              </Box>
            )}
          </Grid>
        ))}
      </Grid>
      <Box mt={4} mb={8} display="flex" justifyContent="center">
        <Button 
          variant="contained" 
          color="primary" 
          onClick={saveAllChanges}
          disabled={!hasChanges}
        >
          שמור שינויים
        </Button>
      </Box>
      <Snackbar open={showSuccessMessage} autoHideDuration={6000} onClose={() => setShowSuccessMessage(false)}>
        <Alert onClose={() => setShowSuccessMessage(false)} severity="success">
          ההגדרות נשמרו בהצלחה
        </Alert>
      </Snackbar>
      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')}>
        <Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Settings;