import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box, CircularProgress, Link } from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login, user, isAuthChecked } = useAuth();

  useEffect(() => {
    if (isAuthChecked && user) {
      navigate('/');
    }
  }, [isAuthChecked, user, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      const response = await login(email.toLowerCase(), password);
      if (response.activeBus) {
        navigate(`/validate-passengers/${response.activeBus.id}`);
      } else {
        navigate('/');
      }
    } catch (err) {
      setError(err.message);
      console.error("Login error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isAuthChecked) {
    return (
      <Container maxWidth="xs">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h4" sx={{ marginBottom: 2 }}>
          העברות אומן
        </Typography>
        <Typography component="h2" variant="h5">
          התחברות
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="אימייל"
            name="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
            inputProps={{ style: { textTransform: 'lowercase' } }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="סיסמה"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'התחבר'}
          </Button>
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Typography variant="body2">
              אינך רשום? <Link component={RouterLink} to="/register">להרשמה לחץ כאן</Link>
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Typography variant="body2">
              שכחת את הסיסמה? <Link component={RouterLink} to="/reset-password-request">לחץ כאן לאיפוס סיסמה</Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;