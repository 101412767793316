import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Box, Typography, TextField, Button, CircularProgress, Snackbar, 
  Grid, Select, MenuItem, InputLabel, FormControl, Paper
} from '@mui/material';
import { useAuth } from '../auth/useAuth';

const UserEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { api } = useAuth();
  const [user, setUser] = useState({ 
    firstName: '', 
    lastName: '', 
    email: '', 
    phone: '',
    role: '',
    status: '' 
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/users/${id}`);
        setUser(response.data);
      } catch (error) {
        console.error('Failed to fetch user', error);
        setError('Failed to load user data');
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [api, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      const updatedUser = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        role: user.role,
        status: user.status // כולל את הסטטוס בעדכון הראשי
      };
      console.log('Sending updated user data:', updatedUser);
      await api.put(`/users/${id}`, updatedUser);
      
      console.log('User updated successfully');
      navigate('/admin/users');
    } catch (error) {
      console.error('Failed to update user', error);
      setError('Failed to update user: ' + (error.response?.data?.message || error.message));
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="300px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
      <Paper elevation={0} sx={{ p: 4, backgroundColor: 'transparent' }}>
        <Typography variant="h5" component="div" gutterBottom align="center" sx={{ mb: 4 }}>
          עריכת משתמש
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="שם פרטי"
              name="firstName"
              value={user.firstName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="שם משפחה"
              name="lastName"
              value={user.lastName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="אימייל"
              name="email"
              value={user.email}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="טלפון"
              name="phone"
              value={user.phone}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="role-label">תפקיד</InputLabel>
              <Select
                labelId="role-label"
                name="role"
                value={user.role}
                onChange={handleChange}
                label="תפקיד"
              >
                <MenuItem value="manager">מנהל</MenuItem>
                <MenuItem value="bus_coordinator">סדרן אוטובוסים</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="status-label">סטטוס</InputLabel>
              <Select
                labelId="status-label"
                name="status"
                value={user.status}
                onChange={handleChange}
                label="סטטוס"
              >
                <MenuItem value="active">פעיל</MenuItem>
                <MenuItem value="inactive">לא פעיל</MenuItem>
                <MenuItem value="pending">ממתין לאישור</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSave} 
            disabled={saving}
          >
            {saving ? 'שומר...' : 'שמור שינויים'}
          </Button>
        </Box>
      </Paper>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
        message={error}
      />
    </Box>
  );
};

export default UserEdit;