import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import RTL from './components/RTL';
import Header from './components/Header';
import BackButton from './components/BackButton';
import AdminLayout from './components/AdminLayout';
import AdminSidebar from './components/AdminSidebar';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import OpenBus from './pages/OpenBus';
import ValidatePassengers from './pages/ValidatePassengers';
import { AuthProvider } from './auth/AuthProvider';
import { useAuth } from './auth/useAuth';
import ManagerDashboard from './pages/ManagerDashboard';
import UserList from './pages/UserList';
import UserEdit from './pages/UserEdit';
import TouristList from './pages/TouristList';
import PassengerDashboard from './pages/PassengerDashboard'; 
import BusList from './pages/BusList'; 
import BusEdit from './pages/BusEdit'; 
import Settings from './pages/Settings';
import BusListUser from './pages/BusListUser';
import ResetPasswordRequest from './pages/ResetPasswordRequest';
import ResetPasswordForm from './pages/ResetPasswordForm';

const theme = createTheme({
  direction: 'rtl',
});

const PrivateRoute = ({ children }) => {
  const { user, isAuthChecked } = useAuth();
  
  if (!isAuthChecked) {
    return <div>טוען...</div>;
  }
  
  if (!user) {
    console.log('User not found in PrivateRoute, redirecting to login');
    return <Navigate to="/login" replace />;
  }
  
  return children;
};

const PublicRoute = ({ children }) => {
  const { user, isAuthChecked } = useAuth();
  
  if (!isAuthChecked) {
    return <div>טוען...</div>;
  }
  
  if (user) {
    return <Navigate to="/" replace />;
  }
  
  return children;
};

const ManagerRoute = ({ children }) => {
  const { user, isAuthChecked } = useAuth();
  
  if (!isAuthChecked) {
    return <div>טוען...</div>;
  }
  
  if (!user || user.role !== 'manager') {
    return <Navigate to="/" replace />;
  }
  
  return children;
};

function AppContent() {
  const { isAuthChecked, user } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setSidebarOpen((prevOpen) => !prevOpen);
  };

  if (!isAuthChecked) {
    return <div>טוען...</div>;
  }

  const isManager = user && user.role === 'manager';

  return (
    <ThemeProvider theme={theme}>
      <RTL>
        <CssBaseline />
        <Router>
        <Header toggleSidebar={toggleSidebar} />
        <BackButton />
        <AdminSidebar isOpen={sidebarOpen} onClose={() => setSidebarOpen(false)} />
          <Routes>
            <Route 
              path="/" 
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/login" 
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              } 
            />
            <Route 
              path="/register" 
              element={
                <PublicRoute>
                  <Register />
                </PublicRoute>
              } 
            />
            <Route 
              path="/reset-password-request" 
              element={
                <PublicRoute>
                  <ResetPasswordRequest />
                </PublicRoute>
              } 
            />
            <Route 
              path="/reset-password/:token" 
              element={
                <PublicRoute>
                  <ResetPasswordForm />
                </PublicRoute>
              } 
            />
            <Route 
              path="/open-bus" 
              element={
                <PrivateRoute>
                  <OpenBus />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/open-bus/:id" 
              element={
                <PrivateRoute>
                  <OpenBus />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/my-buses" 
              element={
                <PrivateRoute>
                  <BusListUser />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/validate-passengers/:busId" 
              element={
                <PrivateRoute>
                  <ValidatePassengers />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/manager-dashboard" 
              element={
                <ManagerRoute>
                  <AdminLayout>
                    <ManagerDashboard />
                  </AdminLayout>
                </ManagerRoute>
              } 
            />
            <Route 
              path="/admin/users" 
              element={
                <ManagerRoute>
                  <AdminLayout>
                    <UserList />
                  </AdminLayout>
                </ManagerRoute>
              } 
            />
            <Route 
              path="/admin/users/edit/:id" 
              element={
                <ManagerRoute>
                  <AdminLayout>
                    <UserEdit />
                  </AdminLayout>
                </ManagerRoute>
              } 
            />
            <Route 
              path="/admin/tourists" 
              element={
                <ManagerRoute>
                  <AdminLayout>
                    <TouristList />
                  </AdminLayout>
                </ManagerRoute>
              } 
            />
            <Route 
              path="/admin/passenger-dashboard" 
              element={
                <ManagerRoute>
                  <AdminLayout>
                    <PassengerDashboard />
                  </AdminLayout>
                </ManagerRoute>
              } 
            />
            <Route 
              path="/admin/buses" 
              element={
                <ManagerRoute>
                  <AdminLayout>
                    <BusList />
                  </AdminLayout>
                </ManagerRoute>
              } 
            />
            <Route 
              path="/edit-bus/:id" 
              element={
                <PrivateRoute>
                  <AdminLayout>
                    <BusEdit />
                  </AdminLayout>
                </PrivateRoute>
              } 
            />
            <Route 
              path="/admin/settings" 
              element={
                <ManagerRoute>
                  <AdminLayout>
                    <Settings />
                  </AdminLayout>
                </ManagerRoute>
              } 
            />
          </Routes>
        </Router>
      </RTL>
    </ThemeProvider>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;