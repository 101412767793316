import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Container, Typography, Box, CircularProgress, Select, MenuItem, FormControl, InputLabel, Snackbar } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';
import { getAllBusesData, createBus, updateBus } from '../api';

const EXEMPT_USERS = ['shimon@kristech.co.il', 'y5321169@gmail.com'];

function OpenBus() {
  const { id } = useParams();
  const { api, user, isAuthChecked, activeBus, updateActiveBus } = useAuth();
  const [allBusesData, setAllBusesData] = useState([]);
  const [busData, setBusData] = useState({
    busNumber: '',
    driverName: '',
    destination: '',
    origin: '',
    driverPhone: '',
    numSeats: '',
    date: '',
    time: '',
    licensingTransit: '',
    contactName: '',
    contactPhone: '',
    additionalContactName: '',
    additionalContactPhone: '',
    direction: '',
    transitManager: '',
    currentResponsibleId: null,
    isClosed: false,
    validationCount: 0,
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [dispatchers, setDispatchers] = useState([]);
  const navigate = useNavigate();
  
  const busDataRef = useRef(busData);

  useEffect(() => {
    busDataRef.current = busData;
  }, [busData]);

  useEffect(() => {
    console.log('busData changed:', busData);
  }, [busData.currentResponsibleId, busData.transitManager]);

  useEffect(() => {
    if (isAuthChecked && !user) {
      navigate('/login');
    }
  }, [isAuthChecked, user, navigate]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await api.get('/settings');
        setSettings(response.data);
        console.log('Fetched settings:', response.data);
        
        const travelMode = response.data.find(s => s.key === 'travel_mode')?.value;
        if (travelMode === 'הלוך') {
          setBusData(prev => ({ ...prev, destination: 'UMAN', direction: 'H' }));
        } else if (travelMode === 'חזור') {
          setBusData(prev => ({ ...prev, origin: 'UMAN', direction: 'C' }));
        }
      } catch (error) {
        console.error('Failed to fetch settings:', error);
      }
    };

    fetchSettings();
  }, [api]);

  useEffect(() => {
    const fetchBusesData = async () => {
      try {
        const data = await getAllBusesData();
        setAllBusesData(data);
      } catch (error) {
        console.error('שגיאה בטעינת נתוני אוטובוסים:', error);
        setError('שגיאה בטעינת נתוני אוטובוסים');
      }
    };
    fetchBusesData();
  }, []);

  useEffect(() => {
    const fetchDispatchers = async () => {
      try {
        const response = await api.get('/users/bus-coordinators');
        setDispatchers(response.data);
      } catch (error) {
        console.error('שגיאה בטעינת נתוני מתאמים:', error);
      }
    };
    fetchDispatchers();
  }, [api]);

  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      const fetchBusDetails = async () => {
        try {
          const response = await api.get(`/buses/${id}`);
          setBusData(response.data);
        } catch (error) {
          console.error('שגיאה בטעינת פרטי האוטובוס:', error);
          setError('שגיאה בטעינת פרטי האוטובוס');
        }
      };
      fetchBusDetails();
    } else {
      // Set current date and time for new bus
      const now = new Date();
      setBusData(prev => ({
        ...prev,
        date: now.toISOString().split('T')[0],
        time: now.toTimeString().slice(0,5)
      }));
    }
  }, [id, api]);

  const isUserExempt = EXEMPT_USERS.includes(user?.email);

  const isFieldDisabled = (fieldName) => {
    if (isEditMode && busData.validationCount > 0 && !isUserExempt) {
      if (['date', 'time', 'busNumber'].includes(fieldName)) {
        return true;
      }
      if ((travelMode === 'הלוך' && fieldName === 'origin') ||
          (travelMode === 'חזור' && fieldName === 'destination')) {
        return true;
      }
    }
    return false;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'numSeats') {
      const numSeats = parseInt(value, 10);
      if (!isNaN(numSeats) && numSeats >= busData.validationCount) {
        setBusData(prev => ({ ...prev, [name]: value }));
      } else {
        setError(`מספר המקומות חייב להיות לפחות ${busData.validationCount}`);
      }
    } else {
      setBusData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleBusSelection = (event) => {
    const busId = event.target.value;

    setBusData(prev => ({
      ...prev,
      busNumber: '',
      numSeats: '',
      driverName: '',
      driverPhone: '',
      licensingTransit: ''
    }));

    
    setBusData(prev => ({ ...prev, busNumber: busId }));
    const selectedBus = allBusesData.find(bus => bus.id === busId);
    if (selectedBus) {
      setBusData(prev => ({
        ...prev,
        numSeats: selectedBus.number_of_seats,
        driverName: selectedBus.driver_name,
        driverPhone: selectedBus.driver_phone,
        licensingTransit: selectedBus.license_number
      }));
    }
  };

  const handleTransitManagerChange = (event) => {
    const selectedDispatcherId = parseInt(event.target.value, 10);
    const selectedDispatcher = dispatchers.find(d => d.id === selectedDispatcherId) || null;
    
    setBusData(prevData => {
      const newData = {
        ...prevData,
        currentResponsibleId: selectedDispatcher ? selectedDispatcher.id : null,
        transitManager: selectedDispatcher ? `${selectedDispatcher.firstName} ${selectedDispatcher.lastName}` : null,
        currentResponsible: selectedDispatcher ? {
          id: selectedDispatcher.id,
          firstName: selectedDispatcher.firstName,
          lastName: selectedDispatcher.lastName,
          // Add other relevant fields from the dispatcher object
        } : null
      };
      
      console.log('Updated busData after changing dispatcher:', newData);
      return newData;
    });
  };

  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    if (newStatus === 'open' && busData.isClosed) {
      setBusData(prev => ({ ...prev, isClosed: false }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
  
    const currentBusData = { ...busDataRef.current };
    console.log('Current bus data before submission:', currentBusData);
  
    const requiredFields = ['date', 'time', 'busNumber', 'driverName', 'destination', 'origin', 'driverPhone', 'numSeats', 'licensingTransit'];
    const missingFields = requiredFields.filter(field => !currentBusData[field]);
  
    if (missingFields.length > 0) {
      setError(`אנא מלא את כל השדות החובה: ${missingFields.join(', ')}`);
      setIsLoading(false);
      return;
    }
  
    const numSeats = parseInt(currentBusData.numSeats, 10);
    if (isNaN(numSeats)) {
      setError('מספר המקומות חייב להיות מספר תקין.');
      setIsLoading(false);
      return;
    }
  
    if (numSeats < currentBusData.validationCount) {
      setError(`מספר המקומות חייב להיות לפחות ${currentBusData.validationCount}`);
      setIsLoading(false);
      return;
    }
  
    let formattedBusData = {
      ...currentBusData,
      numSeats: numSeats,
      misparNesia: 1
    };
  
    if (!isEditMode) {
      // אם זו יצירת אוטובוס חדש
      delete formattedBusData.isClosed;
      delete formattedBusData.validationCount;
      delete formattedBusData.currentResponsibleId;
      formattedBusData.transitManager = user.firstName + ' ' + user.lastName; // או כל ערך ברירת מחדל אחר
    } else {
      // אם זה עדכון אוטובוס קיים
      if (!formattedBusData.transitManager) {
        formattedBusData.transitManager = user.firstName + ' ' + user.lastName;
      }
    }
  
    console.log('Formatted bus data for submission:', formattedBusData);
  
    try {
      let response;
      if (isEditMode) {
        response = await updateBus(id, formattedBusData);
      } else {
        response = await createBus(formattedBusData);
      }
      
      console.log('Server response:', response);
      
      if (response && response.id) {
        const busId = response.id;
        console.log('Bus ID:', busId);
    
        if (response.currentResponsible && response.currentResponsible.id === user.id) {
          await updateActiveBus(busId);
          console.log('Active bus updated successfully');
        } else {
          console.log('Current user is no longer responsible for this bus. Not updating active bus.');
        }
    
        if (!isEditMode) {
          navigate(`/validate-passengers/${busId}`);
        } else {
          setError('האוטובוס עודכן בהצלחה');
        }
  
        setBusData(prevData => ({
          ...prevData,
          ...response,
          currentResponsibleId: response.currentResponsible?.id,
          isClosed: response.isClosed
        }));
  
      } else {
        console.error('Server response does not contain bus ID:', response);
        setError('תגובת השרת לא תקינה. אנא נסה שנית.');
      }
    } catch (err) {
      console.error('Error:', err);
      setError('אירעה שגיאה. אנא נסה שנית.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isAuthChecked || !user || !settings) {
    return (
      <Container maxWidth="xs">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  const travelMode = settings.find(s => s.key === 'travel_mode')?.value;
  const departureDestinations = settings.find(s => s.key === 'departure_destinations')?.value;
  const returnDestinations = settings.find(s => s.key === 'return_destinations')?.value;

  const getDestionations = (destinations) => {
    if (Array.isArray(destinations)) {
      return destinations;
    } else if (typeof destinations === 'string') {
      return destinations.split(',').map(s => s.trim());
    } else {
      console.error('Unexpected destinations format:', destinations);
      return [];
    }
  };

  const departureDests = getDestionations(departureDestinations);
  const returnDests = getDestionations(returnDestinations);


  return (
    <Container maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          {isEditMode ? 'עריכת אוטובוס' : 'פתיחת אוטובוס חדש'}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
            margin="normal"
            required
            fullWidth
            id="date"
            label="תאריך"
            name="date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={busData.date}
            onChange={handleInputChange}
            disabled={isFieldDisabled('date')}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="time"
            label="שעה"
            name="time"
            type="time"
            InputLabelProps={{ shrink: true }}
            value={busData.time}
            onChange={handleInputChange}
            disabled={isFieldDisabled('time')}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="bus-select-label">בחר אוטובוס</InputLabel>
            <Select
              labelId="bus-select-label"
              id="bus-select"
              value={busData.busNumber}
              label="בחר אוטובוס"
              onChange={handleBusSelection}
              disabled={isFieldDisabled('busNumber')}
            >
              {allBusesData.map((bus) => (
                <MenuItem key={bus.id} value={bus.id}>{bus.id}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
              margin="normal"
              fullWidth
              id="busNumber"
              label="מספר אוטובוס"
              value={busData.busNumber}
              InputProps={{
                readOnly: true,
              }}
            />
          <TextField
            margin="normal"
            required
            fullWidth
            id="driverName"
            label="שם הנהג"
            name="driverName"
            value={busData.driverName}
            onChange={handleInputChange}
          />
            {travelMode === 'הלוך' ? (
              <>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="destination"
                  label="יעד"
                  name="destination"
                  value="UMAN"
                  disabled
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel id="origin-label">מוצא</InputLabel>
                  <Select
                    labelId="origin-label"
                    id="origin"
                    value={busData.origin}
                    label="מוצא"
                    onChange={handleInputChange}
                    name="origin"
                    disabled={isFieldDisabled('origin')}
                  >
                    {departureDests.map((dest) => (
                      <MenuItem key={dest} value={dest}>{dest}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : (
              <>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="origin"
                  label="מוצא"
                  name="origin"
                  value="UMAN"
                  disabled
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel id="destination-label">יעד</InputLabel>
                  <Select
                    labelId="destination-label"
                    id="destination"
                    value={busData.destination}
                    label="יעד"
                    onChange={handleInputChange}
                    name="destination"
                    disabled={isFieldDisabled('destination')}
                  >
                    {returnDests.map((dest) => (
                      <MenuItem key={dest} value={dest}>{dest}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          <TextField
            margin="normal"
            required
            fullWidth
            id="licensingTransit"
            label="לוחית רישוי"
            name="licensingTransit"
            value={busData.licensingTransit}
            onChange={handleInputChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="driverPhone"
            label="מספר טלפון של הנהג"
            name="driverPhone"
            value={busData.driverPhone}
            onChange={handleInputChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="numSeats"
            label="מספר מקומות באוטובוס"
            name="numSeats"
            type="number"
            value={busData.numSeats}
            onChange={handleInputChange}
            error={parseInt(busData.numSeats, 10) < busData.validationCount}
            helperText={parseInt(busData.numSeats, 10) < busData.validationCount ? `מספר המקומות חייב להיות לפחות ${busData.validationCount}` : ''}
          />
          <TextField
            margin="normal"
            fullWidth
            id="contactName"
            label="שם איש קשר"
            name="contactName"
            value={busData.contactName}
            onChange={handleInputChange}
          />
          <TextField
            margin="normal"
            fullWidth
            id="contactPhone"
            label="טלפון איש קשר"
            name="contactPhone"
            value={busData.contactPhone}
            onChange={handleInputChange}
          />
          <TextField
            margin="normal"
            fullWidth
            id="additionalContactName"
            label="שם איש קשר נוסף"
            name="additionalContactName"
            value={busData.additionalContactName}
            onChange={handleInputChange}
          />
<TextField
            margin="normal"
            fullWidth
            id="additionalContactPhone"
            label="טלפון איש קשר נוסף"
            name="additionalContactPhone"
            value={busData.additionalContactPhone}
            onChange={handleInputChange}
          />
          {isEditMode && (
            <>
              <FormControl fullWidth margin="normal">
                <InputLabel id="transit-manager-label">בחר סדרן</InputLabel>
                <Select
                  labelId="transit-manager-label"
                  id="transitManager"
                  value={busData.currentResponsibleId || ''}
                  label="בחר סדרן"
                  onChange={handleTransitManagerChange}
                  name="transitManager"
                >
                  <MenuItem value="">
                    <em>ללא</em>
                  </MenuItem>
                  {dispatchers.map((dispatcher) => (
                    <MenuItem key={dispatcher.id} value={dispatcher.id}>
                      {dispatcher.firstName} {dispatcher.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {isEditMode && (
                <FormControl fullWidth margin="normal">
                  <InputLabel id="status-label">סטטוס</InputLabel>
                  <Select
                    labelId="status-label"
                    id="status"
                    value={busData.isClosed ? 'closed' : 'open'}
                    onChange={handleStatusChange}
                    label="סטטוס"
                  >
                    <MenuItem value="open">פתוח</MenuItem>
                    <MenuItem value="closed" disabled={!busData.isClosed}>סגור</MenuItem>
                  </Select>
                </FormControl>
              )}
              <TextField
                margin="normal"
                fullWidth
                id="validationCount"
                label="מספר תיקופים"
                name="validationCount"
                value={busData.validationCount}
                InputProps={{
                  readOnly: true,
                }}
              />
            </>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : (isEditMode ? 'עדכן אוטובוס' : 'פתח אוטובוס')}
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
        message={error}
      />
    </Container>
  );
}

export default OpenBus;