import { createContext } from 'react';

const AuthContext = createContext({
  user: null,
  login: () => {},
  logout: () => {},
  register: () => {},
  isAuthChecked: false,
  api: null,
  activeBus: null,
  setActiveBus: () => {},
});

export default AuthContext;