import React, { useState, useEffect, useCallback } from 'react';
import { Typography, TextField, InputAdornment, Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAuth } from '../auth/useAuth';
import AdminLayout from '../components/AdminLayout';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash/debounce';

const PassengerDashboard = () => {
  const { api } = useAuth();
  const [passengers, setPassengers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [totalRows, setTotalRows] = useState(0);

  const fetchPassengers = useCallback(async (search, page, pageSize) => {
    try {
      setLoading(true);
      const response = await api.get('/passengers', {
        params: {
          search,
          page: page + 1,
          limit: pageSize,
        },
      });
      setPassengers(response.data.data || []);
      setTotalRows(response.data.pagination?.totalItems || 0);
    } catch (error) {
      console.error('Failed to fetch passengers', error);
    } finally {
      setLoading(false);
    }
  }, [api]);

  const debouncedFetchPassengers = useCallback(
    debounce((search, page, pageSize) => fetchPassengers(search, page, pageSize), 300),
    [fetchPassengers]
  );

  useEffect(() => {
    debouncedFetchPassengers(searchTerm, paginationModel.page, paginationModel.pageSize);
  }, [debouncedFetchPassengers, searchTerm, paginationModel]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPaginationModel(prev => ({ ...prev, page: 0 })); // Reset to first page on new search
  };

  const columns: GridColDef[] = [
    { field: 'passengerName', headerName: 'שם הנוסע', flex: 1 },
    { field: 'transitManagerName', headerName: 'שם הסדרן', flex: 1 },
    { field: 'barcode', headerName: 'ברקוד', flex: 1 },
    { 
      field: 'validatedAt', 
      headerName: 'זמן תיקוף', 
      width: 120,
      valueGetter: (params) => {
        
        let validatedAt;
        if (typeof params === 'string') {
          validatedAt = params;
        } else if (params && params.row && params.row.validatedAt) {
          validatedAt = params.row.validatedAt;
        } else {
          return '';
        }
        if (!validatedAt) {
          return '';
        }
        
        try {
          const date = new Date(validatedAt);
          
          if (isNaN(date.getTime())) {
            return 'תאריך לא תקין';
          }
          
          const formattedTime = date.toLocaleTimeString('he-IL', {
            hour: '2-digit',
            minute: '2-digit',
            timeZone: 'Asia/Jerusalem'
          });
          return formattedTime;
        } catch (error) {
          return 'שגיאה בתאריך';
        }
      },
    },
    { field: 'busNumber', headerName: 'מספר אוטובוס', flex: 1 },
    { field: 'direction', headerName: 'כיוון', flex: 1 },
  ];

  return (
    <AdminLayout>
      <Typography variant="h4" gutterBottom align="center">דשבורד תיקופים</Typography>
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="חיפוש..."
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* הוספת גלילה אופקית */}
      <Box sx={{ width: '100%', overflowX: 'auto' }}>
        <Box sx={{ minWidth: 1000 }}> {/* הגדרת מינימום רוחב לטבלה */}
          <DataGrid
            rows={passengers}
            columns={columns}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            rowCount={totalRows}
            disableSelectionOnClick
            disableColumnMenu
            loading={loading}
            autoHeight
            getRowId={(row) => row.id}
            sx={{
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell:focus-within': {
                outline: 'none',
              },
            }}
          />
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default PassengerDashboard;
