import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Box, Typography, TextField, Button, CircularProgress, Snackbar, 
  Grid, Select, MenuItem, InputLabel, FormControl, Paper
} from '@mui/material';
import { useAuth } from '../auth/useAuth';
import { updateBus } from '../api';

const BusEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { api } = useAuth();
  const [bus, setBus] = useState({
    busNumber: '',
    driverName: '',
    destination: '',
    origin: '',
    date: '',
    time: '',
    numSeats: 0,
    validationCount: 0,
    direction: '',
    contactName: '',
    contactPhone: '',
    transitManager: '',
    isClosed: false,
  });
  const [initialBus, setInitialBus] = useState({});
  const [dispatchers, setDispatchers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchBusAndDispatchers = async () => {
      try {
        setLoading(true);
        const [busResponse, dispatchersResponse] = await Promise.all([
          api.get(`/buses/${id}`),
          api.get('/users/bus-coordinators')
        ]);
        setBus(busResponse.data);
        setInitialBus(busResponse.data);
        setDispatchers(dispatchersResponse.data);
      } catch (error) {
        console.error('נכשל בטעינת נתוני האוטובוס או המתאמים', error);
        setError('נכשל בטעינת נתוני האוטובוס או המתאמים');
      } finally {
        setLoading(false);
      }
    };

    fetchBusAndDispatchers();
  }, [api, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBus((prevBus) => ({ ...prevBus, [name]: value }));
  };

  const isFieldDisabled = (fieldName) => {
    if (fieldName === 'validationCount') return true;
    if (bus.direction === 'H') {
      if (fieldName === 'destination') return true;
      if (fieldName === 'origin') return bus.validationCount > 0;
    } else if (bus.direction === 'C') {
      if (fieldName === 'origin') return true;
      if (fieldName === 'destination') return bus.validationCount > 0;
    }
    return false;
  };

  const handleStatusChange = (e) => {
    const isClosed = e.target.value === 'closed';
    setBus((prevBus) => ({ ...prevBus, isClosed }));
  };

  const handleTransitManagerChange = (e) => {
    const selectedDispatcherId = e.target.value;
    const selectedDispatcher = dispatchers.find(d => d.id === selectedDispatcherId) || null;
    
    if (selectedDispatcher) {
      setBus(prevBus => ({
        ...prevBus,
        transitManager: selectedDispatcher.firstName + ' ' + selectedDispatcher.lastName,
        currentResponsibleId: selectedDispatcher.id
      }));
    } else {
      setBus(prevBus => ({ 
        ...prevBus, 
        transitManager: null,
        currentResponsibleId: null
      }));
    }
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      const changedFields = {};
      Object.keys(bus).forEach(key => {
        if (bus[key] !== initialBus[key]) {
          changedFields[key] = bus[key];
        }
      });
      
      if (changedFields.currentResponsible) {
        changedFields.currentResponsibleId = changedFields.currentResponsible.id;
        delete changedFields.currentResponsible;
      }
      
      await updateBus(id, changedFields);
      navigate('/admin/buses');
    } catch (error) {
      console.error('נכשל בעדכון האוטובוס', error);
      setError('נכשל בעדכון האוטובוס: ' + (error.response?.data?.message || error.message));
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="300px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
      <Paper elevation={0} sx={{ p: 4, backgroundColor: 'transparent' }}>
        <Typography variant="h5" component="div" gutterBottom align="center" sx={{ mb: 4 }}>
          עריכת אוטובוס
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="מספר אוטובוס"
              name="busNumber"
              value={bus.busNumber}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="נהג"
              name="driverName"
              value={bus.driverName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="יעד"
              name="destination"
              value={bus.destination}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              disabled={isFieldDisabled('destination')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="מוצא"
              name="origin"
              value={bus.origin}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              disabled={isFieldDisabled('origin')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="תאריך"
              name="date"
              value={bus.date}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="שעה"
              name="time"
              value={bus.time}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="מספר מושבים"
              name="numSeats"
              value={bus.numSeats}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="תיקופים"
              name="validationCount"
              value={bus.validationCount}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              type="number"
              disabled={isFieldDisabled('validationCount')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="direction-label">כיוון</InputLabel>
              <Select
                labelId="direction-label"
                name="direction"
                value={bus.direction}
                onChange={handleChange}
                label="כיוון"
              >
                <MenuItem value="H">הלוך</MenuItem>
                <MenuItem value="C">חזור</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="איש קשר"
              name="contactName"
              value={bus.contactName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="טלפון איש קשר"
              name="contactPhone"
              value={bus.contactPhone}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="סדרן נוכחי"
              name="transitManager"
              value={bus.transitManager}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="transit-manager-label">בחר סדרן חדש</InputLabel>
              <Select
                labelId="transit-manager-label"
                name="transitManager"
                value={bus.currentResponsibleId || ''}
                onChange={handleTransitManagerChange}
                label="בחר סדרן"
              >
                <MenuItem value="">
                  <em>ללא</em>
                </MenuItem>
                {dispatchers.map((dispatcher) => (
                  <MenuItem key={dispatcher.id} value={dispatcher.id}>
                    {dispatcher.firstName} {dispatcher.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined">
              <InputLabel id="status-label">סטטוס</InputLabel>
              <Select
                labelId="status-label"
                name="status"
                value={bus.isClosed ? 'closed' : 'open'}
                onChange={handleStatusChange}
                label="סטטוס"
              >
                <MenuItem value="open">פתוח</MenuItem>
                <MenuItem value="closed">סגור</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSave} 
            disabled={saving}
          >
            {saving ? 'שומר...' : 'שמור שינויים'}
          </Button>
        </Box>
      </Paper>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
        message={error}
      />
    </Box>
  );
};

export default BusEdit;