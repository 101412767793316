import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { requestPasswordReset } from '../api';

function ResetPasswordRequest() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await requestPasswordReset(email);
      setMessage('הוראות לאיפוס הסיסמה נשלחו לכתובת האימייל שלך.');
    } catch (err) {
      setError('בקשת איפוס הסיסמה נכשלה. אנא נסה שנית.');
    }
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          בקשת איפוס סיסמה
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        {message && <Typography color="primary">{message}</Typography>}
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="אימייל"
            name="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            שלח הוראות לאיפוס סיסמה
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default ResetPasswordRequest;