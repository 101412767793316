import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, Container, Typography, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, Grid, Table, TableHead, TableBody, TableRow, TableCell, IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';
import QrScanner from 'react-qr-scanner';
import DeleteIcon from '@mui/icons-material/Delete';
import ManualPassengerInput from '../components/ManualPassengerInput';

function ValidatePassengers() {
  const { api, activeBus, clearActiveBus } = useAuth();
  const { busId } = useParams();
  const [barcode, setBarcode] = useState('');
  const [passengers, setPassengers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [isClosingBus, setIsClosingBus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [busDetails, setBusDetails] = useState(null);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isScanning, setIsScanning] = useState(false);
  const [isManualInput, setIsManualInput] = useState(true);
  const navigate = useNavigate();

  const [isManualInputOpen, setIsManualInputOpen] = useState(false);

  const handleManualSubmit = async (manualPassenger) => {
    setIsLoading(true);
    try {
      const response = await api.post('/passengers/manual-validate', {
        busId,
        ...manualPassenger
      });
      
      // נניח שהשרת מחזיר אובייקט עם שדות 'tourist' ו-'passenger'
      const newPassenger = {
        id: response.data.passenger.id,
        passengerName: `${response.data.tourist.PRATI} ${response.data.tourist.MISHPACHA}`,
        barcode: response.data.tourist.MISPAR_HAZMANA,
        transitManagerName: response.data.passenger.transitManagerName
      };
      
      setPassengers(prevPassengers => [newPassenger, ...prevPassengers]);
      setIsManualInputOpen(false);
    } catch (error) {
      console.error('שגיאה בהזנה ידנית של נוסע:', error);
      setDialogMessage(error.response?.data?.message || 'אירעה שגיאה בעת הזנת הנוסע');
      setOpenDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  const [scannerSettings, setScannerSettings] = useState({
    delay: 300,
    resolution: 600,
    facingMode: "environment",
  });

  const fetchBusDetails = useCallback(async () => {
    try {
      const response = await api.get(`/buses/${busId}`);
      setBusDetails(response.data);
    } catch (error) {
      console.error('Error fetching bus details:', error);
      setDialogMessage('אירעה שגיאה בטעינת פרטי האוטובוס. אנא נסה שנית.');
      setOpenDialog(true);
      setBarcode('');
    }
  }, [api, busId]);

  const fetchPassengers = useCallback(async () => {
    try {
      const response = await api.get(`/passengers/bus/${busId}`);
      setPassengers(response.data);
    } catch (error) {
      console.error('Error fetching passengers:', error);
      setDialogMessage('אירעה שגיאה בטעינת רשימת הנוסעים. אנא נסה שנית.');
      setOpenDialog(true);
      setBarcode('');
    }
  }, [api, busId]);

  const processBarcode = (rawBarcode) => {
    const parts = rawBarcode.split(',');
    if (parts.length >= 2) {
      return parts[1].trim(); 
    }
    return rawBarcode; 
  };

  const handleScan = (data) => {
    if (data) {
      const processedBarcode = processBarcode(data.text);
      setBarcode(processedBarcode);
      setIsScanning(false);
      setIsManualInput(false);
      console.log('Successfully scanned barcode:', processedBarcode);
    }
  };

  const handleError = (err) => {
    console.error('QR Scanner error:', err);
    setDialogMessage('אירעה שגיאה בסריקת הברקוד. אנא נסה שנית.');
    setOpenDialog(true);
    setBarcode('');
    setIsScanning(false);
  };

  const toggleCamera = () => {
    setScannerSettings(prev => ({
      ...prev,
      facingMode: prev.facingMode === "user" ? "environment" : "user"
    }));
  };

  const adjustScannerSettings = () => {
    setScannerSettings(prev => ({
      ...prev,
      delay: prev.delay === 300 ? 500 : 300,
      resolution: prev.resolution === 600 ? 800 : 600,
    }));
  };

  const handleManualInput = (e) => {
    setBarcode(e.target.value);
    setIsManualInput(true);
  };

  useEffect(() => {
    if (!busId) {
      console.error('Bus ID is missing');
      setDialogMessage('מזהה האוטובוס חסר. אנא חזור לדף הקודם.');
      setOpenDialog(true);
      setBarcode('');
      setIsInitialLoading(false);
      return;
    }
    
    fetchBusDetails();
    fetchPassengers();
    setIsInitialLoading(false);
  }, [busId, activeBus, fetchBusDetails, fetchPassengers, navigate]);

  const handleValidate = useCallback(async (e) => {
    if (e) e.preventDefault();
    if (!barcode) return;
    
    const processedBarcode = processBarcode(barcode);
    
    setIsLoading(true);
    try {
      const response = await api.post('/passengers/validate', { 
        busId, 
        barcode: processedBarcode
      });
      
      // כאן אנחנו מניחים שהשרת מחזיר את כל המידע הנדרש, כולל שם הסדרן
      setPassengers(prevPassengers => [response.data, ...prevPassengers]);
      
      setBarcode('');
      setIsManualInput(true);
    } catch (error) {
      console.error('שגיאה בתיקוף נוסע:', error);
      setDialogMessage(error.response?.data?.message || 'אירעה שגיאה בעת תיקוף הנוסע');
      setOpenDialog(true);
      setBarcode('');
    } finally {
      setIsLoading(false);
    }
  }, [api, busId, barcode]);

  const handleRemovePassenger = async (passengerBarcode) => {
    setIsLoading(true);
    try {
      await api.post('/passengers/remove', { busId, barcode: passengerBarcode });
      setPassengers(prevPassengers => prevPassengers.filter(passenger => passenger.barcode !== passengerBarcode));
    } catch (error) {
      console.error('Error removing passenger:', error);
      setDialogMessage('אירעה שגיאה בעת הסרת הנוסע');
      setOpenDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (barcode && !isManualInput) {
      handleValidate();
    }
  }, [barcode, isManualInput, handleValidate]);

  const handleCloseBus = () => {
    if (!busDetails) return;
    console.log(busDetails);
    
    if (passengers.length < busDetails.numberOfSeats) {
      setDialogMessage('לא ניתן לסגור אוטובוס שאינו מלא.');
      setOpenDialog(true);
    } else if (!busDetails.contactName || !busDetails.contactPhone) {
      setDialogMessage('לא ניתן לסגור אוטובוס ללא איש קשר וטלפון של איש קשר.');
      setOpenDialog(true);
    } else {
      setDialogMessage('האם אתה בטוח שברצונך לסגור את האוטובוס?');
      setIsClosingBus(true);
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setIsClosingBus(false);
  };

  const handleConfirmCloseBus = async () => {
    setIsLoading(true);
    try {
      await api.post(`/buses/${busId}/close`);
      await clearActiveBus();
      setOpenDialog(false);
      setIsClosingBus(false);
       navigate('/', { replace: true });

       setTimeout(() => {
        window.history.replaceState(null, '', '/');
      }, 0);
      
    } catch (error) {
      console.error('Error closing bus:', error);
      setDialogMessage('אירעה שגיאה בעת סגירת האוטובוס');
      setOpenDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (isInitialLoading) {
    return <CircularProgress />;
  }

  if (!busDetails) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          תיקוף נוסעים
        </Typography>
        {busDetails && (
          <Box sx={{ mt: 2, mb: 2, width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1" align="center">
                  <span style={{ fontWeight: 'bold' }}>סדרן:</span> {busDetails.transitManager}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" align="center">
                  <span style={{ fontWeight: 'bold' }}>אוטובוס:</span> {busDetails.busNumber}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" align="center">
                  <span style={{ fontWeight: 'bold' }}>יעד:</span> {busDetails.destination}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        <Typography component="h2" variant="h6" sx={{ mt: 2 }}>
          מספר נוסעים שתוקפו: {passengers.length} / {busDetails?.numSeats || 'טוען...'}
        </Typography>
        <Box component="form" onSubmit={handleValidate} sx={{ mt: 1, width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="barcode"
            label="הזן מספר הזמנה"
            name="barcode"
            autoFocus
            value={barcode}
            onChange={handleManualInput}
            disabled={isLoading}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'תקף נוסע'}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setIsScanning(true)}
            sx={{ mt: 1, mb: 2 }}
            disabled={isLoading}
          >
            סרוק ברקוד
          </Button>
        </Box>

        <Button
        fullWidth
        variant="outlined"
        color="secondary"
        onClick={() => setIsManualInputOpen(true)}
        sx={{ mt: 1, mb: 2 }}
        disabled={isLoading}
      >
        הזן הזמנה ידנית
      </Button>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={handleCloseBus}
          sx={{ mt: 2 }}
          disabled={isLoading || passengers.length < (busDetails?.numberOfSeats || 0)}
        >
          סגירת אוטובוס
        </Button>
        <Typography component="h2" variant="h6" sx={{ mt: 4 }}>
          נוסעים שתוקפו:
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>שם</TableCell>
              <TableCell>הזמנה</TableCell>
              <TableCell>סדרן</TableCell>
              <TableCell>הסר</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {passengers.map((passenger) => (
              <TableRow key={passenger.id}>
                <TableCell>{passenger.passengerName}</TableCell>
                <TableCell>{passenger.barcode}</TableCell>
                <TableCell>{passenger.transitManagerName}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleRemovePassenger(passenger.barcode)} disabled={isLoading}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>הודעה</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isClosingBus ? (
            <>
              <Button onClick={handleCloseDialog} disabled={isLoading}>ביטול</Button>
              <Button onClick={handleConfirmCloseBus} autoFocus disabled={isLoading}>
                {isLoading ? <CircularProgress size={24} /> : 'אישור'}
              </Button>
            </>
          ) : (
            <Button onClick={handleCloseDialog} autoFocus>
              אישור
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog open={isScanning} onClose={() => setIsScanning(false)}>
        <DialogTitle>סריקת ברקוד</DialogTitle>
        <DialogContent>
          <QrScanner
            delay={scannerSettings.delay}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}
            constraints={{
              video: { 
                facingMode: scannerSettings.facingMode,
                width: { min: scannerSettings.resolution },
                height: { min: scannerSettings.resolution },
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleCamera}>החלף מצלמה</Button>
          <Button onClick={adjustScannerSettings}>שנה הגדרות סריקה</Button>
          <Button onClick={() => setIsScanning(false)}>ביטול</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isManualInputOpen}
        onClose={() => setIsManualInputOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <ManualPassengerInput 
            onSubmit={handleManualSubmit}
            isLoading={isLoading}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default ValidatePassengers;