import React, { useState, useEffect, useCallback } from 'react';
import { Typography, TextField, InputAdornment, Box, Button, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAuth } from '../auth/useAuth';
import AdminLayout from '../components/AdminLayout';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

const BusList = () => {
  const { api } = useAuth();
  const [buses, setBuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [totalRows, setTotalRows] = useState(0);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [busToDelete, setBusToDelete] = useState(null);

  const fetchBuses = useCallback(async (search, page, pageSize) => {
    try {
      setLoading(true);
      const response = await api.get('/buses', {
        params: {
          search,
          page: page + 1,
          limit: pageSize,
        },
      });
      setBuses(response.data.data);
      setTotalRows(response.data.pagination.totalItems);
    } catch (error) {
      console.error('Failed to fetch buses', error);
    } finally {
      setLoading(false);
    }
  }, [api]);

  const debouncedFetchBuses = useCallback(
    debounce((search, page, pageSize) => fetchBuses(search, page, pageSize), 300),
    [fetchBuses]
  );

  useEffect(() => {
    debouncedFetchBuses(searchTerm, paginationModel.page, paginationModel.pageSize);
  }, [debouncedFetchBuses, searchTerm, paginationModel]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPaginationModel(prev => ({ ...prev, page: 0 }));
  };

  const handleEditBus = (busId) => {
    navigate(`/open-bus/${busId}`);
  };

  const handleDeleteBus = (bus) => {
    setBusToDelete(bus);
    setOpenDialog(true);
  };

  const confirmDeleteBus = async () => {
    try {
      await api.delete(`/buses/${busToDelete.id}`);
      setOpenDialog(false);
      // רענון רשימת האוטובוסים לאחר המחיקה
      debouncedFetchBuses(searchTerm, paginationModel.page, paginationModel.pageSize);
    } catch (error) {
      console.error('Failed to delete bus', error);
      // כאן אתה יכול להוסיף הודעת שגיאה למשתמש
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: 'פעולות',
      width: 200,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditBus(params.row.id)}
            sx={{ mr: 1 }}
          >
            ערוך
          </Button>
          {params.row.validationCount === 0 && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => handleDeleteBus(params.row)}
            >
              מחק
            </Button>
          )}
        </>
      ),
    },
    { field: 'busNumber', headerName: 'מספר אוטובוס', width: 100 },
    { field: 'transitManager', headerName: 'סדרן', width: 100 },
    { field: 'driverName', headerName: 'נהג', width: 150 },
    { field: 'destination', headerName: 'יעד', width: 150 },
    { field: 'origin', headerName: 'מוצא', width: 150 },
    { field: 'date', headerName: 'תאריך', width: 120 },
    { field: 'time', headerName: 'שעה', width: 100 },
    { field: 'numSeats', headerName: 'מושבים', width: 100 },
    { field: 'validationCount', headerName: 'תיקופים', width: 100 },
    { field: 'direction', headerName: 'כיוון', width: 100 },
    { field: 'contactName', headerName: 'איש קשר', width: 150 },
    { field: 'contactPhone', headerName: 'טלפון איש קשר', width: 150 },
    { field: 'driverPhone', headerName: 'טלפון נהג', width: 150 },
    { field: 'licensingTransit', headerName: 'לוחית רישוי', width: 150 },
    { 
      field: 'isClosed', 
      headerName: 'סטטוס', 
      width: 150,
      renderCell: (params) => {
        return (
          <div>
            {params.value ? 'סגור' : 'פתוח'}
          </div>
        );
      }
    },
    { 
      field: 'openedBy', 
      headerName: 'סדרן פותח', 
      width: 150,
      renderCell: (params) => {
        const { firstName, lastName } = params.row.openedBy || {};
        return <div>{firstName && lastName ? `${firstName} ${lastName}` : 'לא זמין'}</div>;
      }
    },
    { 
      field: 'closedBy', 
      headerName: 'סדרן סוגר', 
      width: 150,
      renderCell: (params) => {
        const { firstName, lastName } = params.row.closedBy || {};
        return <div>{firstName && lastName ? `${firstName} ${lastName}` : 'לא זמין'}</div>;
      }
    },
    { 
      field: 'closedAt', 
      headerName: 'שעת סגירה', 
      width: 120,
      valueGetter: (params) => {
        
        let closedAt;
        if (typeof params === 'string') {
          closedAt = params;
        } else if (params && params.row && params.row.closedAt) {
          closedAt = params.row.closedAt;
        } else {
          return '';
        }
        if (!closedAt) {
          return '';
        }
        
        try {
          const date = new Date(closedAt);
          
          if (isNaN(date.getTime())) {
            return 'תאריך לא תקין';
          }
          
          const formattedTime = date.toLocaleTimeString('he-IL', {
            hour: '2-digit',
            minute: '2-digit',
            timeZone: 'Asia/Jerusalem'
          });
          return formattedTime;
        } catch (error) {
          return 'שגיאה בתאריך';
        }
      },
    },

  ];

  return (
    <AdminLayout>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ mb: 3 }}>
          רשימת אוטובוסים
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="חיפוש..."
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Paper>

      <Box sx={{ width: '100%', height: 500 }}>
        <DataGrid
          rows={buses}
          columns={columns}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          rowCount={totalRows}
          pageSizeOptions={[10, 25, 50]}
          disableSelectionOnClick
          loading={loading}
          autoHeight
          getRowId={(row) => row.id}
          sx={{
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
          }}
        />
      </Box>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"אישור מחיקת אוטובוס"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            האם אתה בטוח שברצונך למחוק את האוטובוס מספר {busToDelete?.busNumber}? .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            ביטול
          </Button>
          <Button onClick={confirmDeleteBus} color="error" autoFocus>
            מחק
          </Button>
        </DialogActions>
      </Dialog>
    </AdminLayout>
  );
};

export default BusList;