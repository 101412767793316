import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import heLocale from 'date-fns/locale/he';

function ManualOrderInput({ onSubmit, systemSettings }) {
  const [order, setOrder] = useState({
    idNumber: '',
    lastNameEn: '',
    firstNameEn: '',
    birthDate: null,
    passportNumber: '',
    citizenship: 'אחר',
    paymentMethod: 'מזומן',
    originOrDestination: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setOrder(prev => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (date) => {
    setOrder(prev => ({ ...prev, birthDate: date }));
  };

  const handleSubmit = () => {
    const formattedOrder = {
      ...order,
      birthDate: order.birthDate ? format(order.birthDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null,
    };
    onSubmit(formattedOrder);
  };

  const travelMode = systemSettings.find(s => s.key === 'travel_mode')?.value;
  const isOutbound = travelMode === 'הלוך';

  const getDestionations = (destinations) => {
    if (Array.isArray(destinations)) {
      return destinations;
    } else if (typeof destinations === 'string') {
      return destinations.split(',').map(s => s.trim());
    } else {
      console.error('פורמט יעדים לא צפוי:', destinations);
      return [];
    }
  };

  const departureDestinations = systemSettings.find(s => s.key === 'departure_destinations')?.value;
  const returnDestinations = systemSettings.find(s => s.key === 'return_destinations')?.value;

  const departureDests = getDestionations(departureDestinations);
  const returnDests = getDestionations(returnDestinations);

  const locations = isOutbound ? departureDests : returnDests;
  const locationLabel = isOutbound ? 'מוצא' : 'יעד';

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        הזנת פרטי הזמנה ידנית
      </Typography>
      <Select
        fullWidth
        name="citizenship"
        value={order.citizenship}
        onChange={handleInputChange}
        sx={{ mt: 2, mb: 1 }}
      >
        <MenuItem value="אחר">אחר</MenuItem>
        <MenuItem value="ישראלי">ישראלי</MenuItem>
      </Select>
      {order.citizenship === 'ישראלי' && (
        <TextField
          margin="normal"
          required
          fullWidth
          name="idNumber"
          label="תעודת זהות"
          value={order.idNumber}
          onChange={handleInputChange}
        />
      )}
      <TextField
        margin="normal"
        required
        fullWidth
        name="lastNameEn"
        label="שם משפחה באנגלית"
        value={order.lastNameEn}
        onChange={handleInputChange}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="firstNameEn"
        label="שם פרטי באנגלית"
        value={order.firstNameEn}
        onChange={handleInputChange}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={heLocale}>
        <DatePicker
          label="תאריך לידה"
          value={order.birthDate}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
          format="dd/MM/yyyy"
        />
      </LocalizationProvider>
      <TextField
        margin="normal"
        required
        fullWidth
        name="passportNumber"
        label="מספר דרכון"
        value={order.passportNumber}
        onChange={handleInputChange}
      />

      <Select
        fullWidth
        name="paymentMethod"
        value={order.paymentMethod}
        onChange={handleInputChange}
        sx={{ mt: 2, mb: 1 }}
      >
        <MenuItem value="מזומן">מזומן</MenuItem>
        <MenuItem value="פאייכלה">פאייכלה</MenuItem>
        <MenuItem value="אשראי">אשראי</MenuItem>
      </Select>

      <FormControl fullWidth margin="normal">
        <InputLabel id="location-label">{locationLabel}</InputLabel>
        <Select
          labelId="location-label"
          name="originOrDestination"
          value={order.originOrDestination}
          onChange={handleInputChange}
          label={locationLabel}
        >
          {locations.map((location) => (
            <MenuItem key={location} value={location}>{location}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        fullWidth
        variant="contained"
        onClick={handleSubmit}
        sx={{ mt: 2 }}
      >
        סיים והוסף הזמנה
      </Button>
    </Box>
  );
}

export default ManualOrderInput;