import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';

function Register() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const { register } = useAuth();

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setMessage('');
    setIsSuccess(false);
  
    if (!validatePassword(password)) {
      setMessage('הסיסמה חייבת להכיל לפחות 8 תווים, אות גדולה, אות קטנה, מספר ותו מיוחד');
      return;
    }

    if (password !== confirmPassword) {
      setMessage('הסיסמאות אינן תואמות');
      return;
    }
  
    try {
      const result = await register({ firstName, lastName, email: email.toLowerCase(), phone, password });
      console.log('Registration result:', result);
  
      setIsSuccess(result.success);
      setMessage(result.message);
  
      if (result.success) {
        console.log('User registered successfully');
      }
    } catch (error) {
      console.error('Registration error:', error);
      setIsSuccess(false);
      setMessage('אירעה שגיאה בתהליך ההרשמה. אנא נסה שנית.');
    }
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          הרשמה
        </Typography>
        {message && (
          <Typography color={isSuccess ? "primary" : "error"} sx={{ mt: 2, mb: 2 }}>
            {message}
          </Typography>
        )}
        <Box component="form" onSubmit={handleRegister} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="שם פרטי"
            name="firstName"
            autoFocus
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="שם משפחה"
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="אימייל"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
            inputProps={{ style: { textTransform: 'lowercase' } }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="phone"
            label="מספר טלפון"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="סיסמה"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            helperText="הסיסמה חייבת להכיל לפחות 8 תווים, אות גדולה, אות קטנה, מספר ותו מיוחד"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="אימות סיסמה"
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            הירשם
          </Button>
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Typography variant="body2">
              משתמש רשום? <Link component={RouterLink} to="/login">לחץ כאן בכדי להתחבר</Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default Register;