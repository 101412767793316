import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';

function Header({ toggleSidebar }) {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleBusClick = () => {
    navigate('/my-buses');
  };

  const handleOpenBusClick = () => {
    navigate('/open-bus');
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };


  return (
    <AppBar position="fixed">
      <Toolbar sx={{ paddingLeft: 2, paddingRight: 2 }}>
        {user && (
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              width: '100%'
            }}
          >

            {user.role === 'manager' && (
              <IconButton
                color="inherit"
                aria-label="פתח תפריט"
                edge="end"
                onClick={toggleSidebar}
              >
                <MenuIcon />
              </IconButton>
            )}

            <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
              שלום {user.firstName}
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>

              <IconButton 
                color="inherit" 
                onClick={handleBusClick}
                aria-label="רשימת אוטובוסים"
              >
                <DirectionsBusIcon />
              </IconButton>
              <IconButton 
                color="inherit" 
                onClick={handleOpenBusClick}
                aria-label="פתח אוטובוס חדש"
              >
                <AddIcon />
              </IconButton>
              <IconButton
                color="inherit"
                onClick={handleLogout}
                aria-label="התנתקות"
                edge="start"
              >
                <LogoutIcon />
              </IconButton>
            </Box>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;