import React, { useState, useEffect, useCallback } from 'react';
import { Typography, TextField, InputAdornment, Box, Button, Dialog } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAuth } from '../auth/useAuth';
import AdminLayout from '../components/AdminLayout';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash/debounce';
import ManualOrderInput from '../components/ManualOrderInput';

const TouristList = () => {
  const { api } = useAuth();
  const [tourists, setTourists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [isManualInputOpen, setIsManualInputOpen] = useState(false);
  const [systemSettings, setSystemSettings] = useState(null);

  const fetchTourists = useCallback(async (search, page, pageSize) => {
    try {
      setLoading(true);
      const response = await api.get('/external-api/tourists', {
        params: {
          search,
          page: page + 1,
          limit: pageSize,
        },
      });
      setTourists(response.data.tourists);
      setTotalRows(response.data.total);
    } catch (error) {
      console.error('נכשל בטעינת רשימת התיירים', error);
    } finally {
      setLoading(false);
    }
  }, [api]);

  const fetchSystemSettings = useCallback(async () => {
    try {
      const response = await api.get('/settings');
      setSystemSettings(response.data);
    } catch (error) {
      console.error('נכשל בטעינת הגדרות המערכת', error);
    }
  }, [api]);

  useEffect(() => {
    fetchSystemSettings();
  }, [fetchSystemSettings]);

  const debouncedFetchTourists = useCallback(
    debounce((search, page, pageSize) => fetchTourists(search, page, pageSize), 300),
    [fetchTourists]
  );

  useEffect(() => {
    debouncedFetchTourists(searchTerm, paginationModel.page, paginationModel.pageSize);
  }, [debouncedFetchTourists, searchTerm, paginationModel]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPaginationModel(prev => ({ ...prev, page: 0 }));
  };

  const handleManualSubmit = async (manualOrder) => {
    try {
      await api.post('/external-api/manual-add', manualOrder);
      setIsManualInputOpen(false);
      // רענון רשימת התיירים לאחר הוספה
      fetchTourists(searchTerm, paginationModel.page, paginationModel.pageSize);
    } catch (error) {
      console.error('שגיאה בהוספת הזמנה ידנית:', error);
      // כאן תוכל להוסיף הודעת שגיאה למשתמש
    }
  };

  const columns: GridColDef[] = [
    { field: 'MISPAR_HAZMANA', headerName: 'מספר הזמנה', width: 130 },
    { field: 'STATUS_HAZMANA', headerName: 'סטטוס הזמנה', width: 130 },
    { field: 'MISHPACHA', headerName: 'שם משפחה ', width: 130 },
    { field: 'PRATI', headerName: 'שם פרטי', width: 130 },
    { field: 'NESIOT_HALOCH', headerName: 'נסיעות הלוך', width: 130 },
    { field: 'ORIGIN_HALOCH', headerName: 'מוצא הלוך', width: 130 },
    { field: 'DESTINATION_HALOCH', headerName: 'יעד הלוך', width: 130 },
    { field: 'DATE_BUS_HALOCH', headerName: 'תאריך נסיעת הלוך', width: 130 },
    { field: 'TIME_BUS_HALOCH', headerName: 'שעת נסיעת הלוך', width: 130 },
    { field: 'NESIOT_CHAZOR', headerName: 'נסיעות חזור', width: 130 },
    { field: 'ORIGIN_CHAZOR', headerName: 'מוצא חזור', width: 130 },
    { field: 'DESTINATION_CHAZOR', headerName: 'יעד חזור', width: 130 },
    { field: 'DATE_BUS_CHAZOR', headerName: 'תאריך נסיעת חזור', width: 160 },
    { field: 'TIME_BUS_CHAZOR', headerName: 'שעת נסיעת חזור', width: 160 },
    { field: 'COMPANY', headerName: 'חברה', width: 160 },
    { field: 'PAYMENT_METHOD', headerName: 'אמצעי תשלום', width: 160 },
  ];

  
  
  return (
    <AdminLayout>
      <Typography variant="h4" gutterBottom align="center">
        רשימת הזמנות
      </Typography>
      
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <TextField
          sx={{ flexGrow: 1, mr: 2 }}
          variant="outlined"
          placeholder="חיפוש לפי מספר הזמנה, תז, שם פרטי, שם משפחה.."
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsManualInputOpen(true)}
        >
          הוספת הזמנה ידנית
        </Button>
      </Box>

      <Box sx={{ width: '100%', overflowX: 'auto' }}>
        <Box sx={{ minWidth: 1000 }}>
          <DataGrid
            rows={tourists}
            columns={columns}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            rowCount={totalRows}
            disableSelectionOnClick
            disableColumnMenu
            loading={loading}
            autoHeight
            getRowId={(row) => row.MISPAR_HAZMANA}
            sx={{
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell:focus-within': {
                outline: 'none',
              },
            }}
          />
        </Box>
      </Box>

      <Dialog
        open={isManualInputOpen}
        onClose={() => setIsManualInputOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <ManualOrderInput 
          onSubmit={handleManualSubmit}
          systemSettings={systemSettings}
          isAdminMode={true}
        />
      </Dialog>
    </AdminLayout>
  );
};

export default TouristList;