import React, { useState } from 'react';
import { Box } from '@mui/material';
import AdminSidebar from './AdminSidebar';
import Header from './Header';

const AdminLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false); // ניהול ה-state של פתיחת הסיידבר

  const toggleSidebar = () => {
    setSidebarOpen((prevOpen) => !prevOpen); // פונקציה שמבצעת toggle לסיידבר
  };

  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      {/* מעבירים את הפונקציה toggleSidebar ל-Header */}
      <Header toggleSidebar={toggleSidebar} />

      {/* הסיידבר נפתח ונסגר לפי ה-state */}
      <AdminSidebar isOpen={sidebarOpen} onClose={() => {
        setSidebarOpen(false);
      }} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1,
          marginTop: '64px', // גובה ה-AppBar
          transition: 'opacity 0.3s ease',
          overflowX: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AdminLayout;
