import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';


function Dashboard() {
    const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {/* <Typography component="h1" variant="h4" gutterBottom>
          דשבורד
        </Typography> */}
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => navigate('/open-bus')}
          sx={{ mt: 2 }}
        >
          פתח אוטובוס חדש
        </Button>
      </Box>
    </Container>
  );
}

export default Dashboard;