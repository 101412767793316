// src/config/settingsConfig.js

export const settingsConfig = [
    {
      key: 'travel_mode',
      displayName: 'מצב נסיעה',
      type: 'select',
      options: ['הלוך', 'חזור'],
      category: 'כללי'
    },
    {
      key: 'travel_direction_change_date',
      displayName: 'תאריך שינוי כיוון נסיעה',
      type: 'date',
      category: 'כללי'
    },
    {
      key: 'departure_destinations',
      displayName: 'יעדי מוצא',
      type: 'array',
      category: 'יעדים'
    },
    {
      key: 'return_destinations',
      displayName: 'יעדי חזור',
      type: 'array',
      category: 'יעדים'
    },
    // ... הגדרות נוספות אם יש
  ];
  
  export const getSettingsByCategory = () => {
    return settingsConfig.reduce((acc, setting) => {
      if (!acc[setting.category]) {
        acc[setting.category] = [];
      }
      acc[setting.category].push(setting);
      return acc;
    }, {});
  };