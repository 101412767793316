import axios from 'axios';
import qs from 'qs';


const API_URL = process.env.REACT_APP_SERVER || 'http://localhost:3333';

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    
    failedQueue = [];
  };

export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    console.error('No refresh token found in localStorage');
    throw new Error('No refresh token found');
  }
  console.log('Attempting to refresh token');
  try {
    const response = await api.post('/auth/refresh', { refresh_token: refreshToken });
    console.log('Refresh token response:', response.data);
    const { access_token: newAccessToken, refresh_token: newRefreshToken } = response.data;
    setAuthToken(newAccessToken);
    localStorage.setItem('accessToken', newAccessToken);
    localStorage.setItem('refreshToken', newRefreshToken);
    console.log('New tokens set successfully');
    return { newAccessToken, newRefreshToken };
  } catch (error) {
    console.error('Error refreshing token:', error.response?.data || error.message);
    throw error;
  }
};

export const requestPasswordReset = async (email) => {
  try {
    const response = await api.post('/auth/reset-password-request', { email });
    return response.data;
  } catch (error) {
    console.error('Error requesting password reset:', error.response?.data || error.message);
    throw error;
  }
};

export const resetPassword = async (token, newPassword) => {
  try {
    const response = await api.post('/auth/reset-password', { token, newPassword });
    return response.data;
  } catch (error) {
    console.error('Error resetting password:', error.response?.data || error.message);
    throw error;
  }
};

export const getSettings = async () => {
  try {
    const response = await api.get('/settings/bus-creation');
    return response.data;
  } catch (error) {
    console.error('Error fetching settings:', error);
    throw error;
  }
};

export const createBus = async (busData) => {
  try {
    const response = await api.post('/buses', qs.stringify(busData), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return response.data;
  } catch (error) {
    console.error('שגיאה ביצירת האוטובוס:', error.response?.data || error.message);
    throw error;
  }
};

export const updateBus = async (id, busData) => {
  try {
    const response = await api.put(`/buses/${id}`, busData);
    return response.data;
  } catch (error) {
    console.error('שגיאה בעדכון האוטובוס:', error.response?.data || error.message);
    throw error;
  }
};

export const deleteBus = async (id) => {
  try {
    const response = await api.delete(`/buses/${id}`);
    return response.data;
  } catch (error) {
    console.error('שגיאה במחיקת האוטובוס:', error.response?.data || error.message);
    throw error;
  }
};

export const getAllBusesData = async () => {
  try {
    const response = await api.get('/buses/all-data');
    return response.data;
  } catch (error) {
    console.error('שגיאה בקבלת נתוני כל האוטובוסים:', error.response?.data || error.message);
    throw error;
  }
};

export const searchTourists = async (searchTerm, limit = 5) => {
  try {
    const response = await api.get(`/external-api/tourists?search=${searchTerm}&limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error('שגיאה בחיפוש הזמנות:', error.response?.data || error.message);
    throw error;
  }
};

api.interceptors.response.use(
  (response) => {
    console.log('Server Response:', response.data);
    return response;
  },
  async (error) => {
    console.log('Server Error Response:', error.response ? error.response.data : 'No response data');

    const originalRequest = error.config;

    // בדיקה ספציפית לשגיאות התחברות
    if (originalRequest.url.includes('/auth/login')) {
      console.error('Authentication error:', error.response ? error.response.data : error.message);
      return Promise.reject(error);
    }

    if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return api(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          console.error('No refresh token found in localStorage');
          throw new Error('No refresh token found');
        }
        console.log('Attempting to refresh token');
        const { newAccessToken, newRefreshToken } = await refreshAccessToken();
        processQueue(null, newAccessToken);
        originalRequest.headers['Authorization'] = 'Bearer ' + newAccessToken;
        return api(originalRequest);
      } catch (err) {
        processQueue(err, null);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        console.error('Token refresh failed:', err);
        window.dispatchEvent(new Event('logout'));
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
      }
    }

    // טיפול כללי בשגיאות
    if (error.response) {
      console.error('Request failed:', error.response.status, error.response.data);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error setting up request:', error.message);
    }

    return Promise.reject(error);
  }
);

