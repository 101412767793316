import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/useAuth';
import { Grid, Paper, Typography } from '@mui/material';
import AdminLayout from '../components/AdminLayout';

const ManagerDashboard = () => {
  const { api } = useAuth();
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await api.get('/dashboard');
        setDashboardData(response.data);
      } catch (error) {
        console.error('Failed to fetch dashboard data', error);
      }
    };

    fetchDashboardData();
  }, [api]);

  if (!dashboardData) return <div>טוען...</div>;

  return (
    <AdminLayout>
      <Typography variant="h4" gutterBottom align="center">דשבורד מנהלים</Typography>
      <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
              <Typography variant="h6" align="center">סטטיסטיקות משתמשים</Typography>
              <Typography align="center">סה"כ משתמשים: {dashboardData.users.totalUsers}</Typography>
              <Typography align="center">משתמשים פעילים: {dashboardData.users.activeUsers}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
              <Typography variant="h6" align="center">סטטיסטיקות אוטובוסים</Typography>
              <Typography align="center">סה"כ אוטובוסים: {dashboardData.buses.totalBuses}</Typography>
              <Typography align="center">אוטובוסים פעילים: {dashboardData.buses.activeBuses}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
              <Typography variant="h6" align="center">סטטיסטיקות נוסעים</Typography>
              <Typography align="center">סה"כ נוסעים: {dashboardData.passengers.totalPassengers}</Typography>
              <Typography align="center">תיקופים היום: {dashboardData.passengers.validationsToday}</Typography>
            </Paper>
          </Grid>
          </Grid>
    </AdminLayout>
  );
};

export default ManagerDashboard;