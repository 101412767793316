import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Select, MenuItem, CircularProgress } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parse, isValid } from 'date-fns';
import { searchTourists } from '../api'; // וודא שהנתיב נכון

function ManualPassengerInput({ onSubmit, isLoading }) {
  const [passenger, setPassenger] = useState({
    idNumber: '',
    lastNameEn: '',
    firstNameEn: '',
    birthDate: '',
    passportNumber: '',
    citizenship: 'אחר',
    paymentMethod: 'מזומן'
  });
  const [isSearching, setIsSearching] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPassenger(prev => ({ ...prev, [name]: value }));
  };

  const handleSearch = async (searchField) => {
    if (passenger[searchField].length >= 3) {
      setIsSearching(true);
      try {
        const results = await searchTourists(passenger[searchField]);
        console.log('Search results:', results); // לצורכי דיבאג
        if (results && results.tourists && results.tourists.length > 0) {
          const tourist = results.tourists[0];
          setPassenger(prev => ({
            idNumber: tourist.TZ || '',
            lastNameEn: tourist.MISHPACHA || '',
            firstNameEn: tourist.PRATI || '',
            birthDate: tourist.BIRTH_DATE || '',
            passportNumber: tourist.PASSPORT_NUMBER || '',
            citizenship: prev.citizenship, // שומר על ערך האזרחות הקיים
            paymentMethod: prev.paymentMethod // שומר על שיטת התשלום הקיימת
          }));
        } else {
          alert('לא נמצאו תוצאות');
        }
      } catch (error) {
        console.error('Error searching for tourists:', error);
        alert('אירעה שגיאה בחיפוש');
      } finally {
        setIsSearching(false);
      }
    } else {
      alert('נא להזין לפחות 3 תווים');
    }
  };

  const handleDateChange = (date) => {
    if (date && isValid(date)) {
      const formattedDate = format(date, 'dd/MM/yyyy');
      const isoDate = format(date, 'yyyy-MM-dd');
      setPassenger(prev => ({ ...prev, birthDate: formattedDate, birthDateISO: isoDate }));
    } else {
      setPassenger(prev => ({ ...prev, birthDate: '', birthDateISO: null }));
    }
  };

  const handleSubmit = () => {
    const submissionData = {
      ...passenger,
      birthDate: passenger.birthDateISO
    };
    delete submissionData.birthDateISO;
    onSubmit(submissionData);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        הזנת פרטי נוסע ידנית
      </Typography>
      <Select
        fullWidth
        name="citizenship"
        value={passenger.citizenship}
        onChange={handleInputChange}
        sx={{ mt: 2, mb: 1 }}
      >
        <MenuItem value="אחר">אחר</MenuItem>
        <MenuItem value="ישראלי">ישראלי</MenuItem>
      </Select>
      {passenger.citizenship === 'ישראלי' && (
        <>
          <TextField
            margin="normal"
            required
            fullWidth
            name="idNumber"
            label="תעודת זהות"
            value={passenger.idNumber}
            onChange={handleInputChange}
          />
          <Button
            fullWidth
            variant="outlined"
            onClick={() => handleSearch('idNumber')}
            disabled={isSearching}
            sx={{ mt: 1, mb: 2 }}
          >
            {isSearching ? <CircularProgress size={24} /> : 'חפש לפי תעודת זהות'}
          </Button>
        </>
      )}
      <TextField
        margin="normal"
        fullWidth
        name="passportNumber"
        label="מספר דרכון"
        value={passenger.passportNumber}
        onChange={handleInputChange}
      />
      <Button
        fullWidth
        variant="outlined"
        onClick={() => handleSearch('passportNumber')}
        disabled={isSearching}
        sx={{ mt: 1, mb: 2 }}
      >
        {isSearching ? <CircularProgress size={24} /> : 'חפש לפי מספר דרכון'}
      </Button>
      <TextField
        margin="normal"
        required
        fullWidth
        name="lastNameEn"
        label="שם משפחה"
        value={passenger.lastNameEn}
        onChange={handleInputChange}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="firstNameEn"
        label="שם פרטי"
        value={passenger.firstNameEn}
        onChange={handleInputChange}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="תאריך לידה"
          value={passenger.birthDate ? parse(passenger.birthDate, 'dd/MM/yyyy', new Date()) : null}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
          format="dd/MM/yyyy"
        />
      </LocalizationProvider>
      <Select
        fullWidth
        name="paymentMethod"
        value={passenger.paymentMethod}
        onChange={handleInputChange}
        sx={{ mt: 2, mb: 1 }}
      >
        <MenuItem value="מזומן">מזומן</MenuItem>
        <MenuItem value="פאייכלה">פאייכלה</MenuItem>
        <MenuItem value="אשראי">אשראי</MenuItem>
      </Select>

      <Button
        fullWidth
        variant="contained"
        onClick={handleSubmit}
        sx={{ mt: 2 }}
        disabled={isLoading || isSearching}
      >
        סיים ותקף
      </Button>
    </Box>
  );
}

export default ManualPassengerInput;