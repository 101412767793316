import React, { useState, useEffect } from "react";
import AuthContext from "./AuthContext";
import {
  api,
  setAuthToken,
  refreshAccessToken as apiRefreshAccessToken,
} from "../api";

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [activeBus, setActiveBus] = useState(null);

  const isTokenValid = (token) => {
    if (!token) return false;
    const tokenParts = token.split(".");
    if (tokenParts.length !== 3) return false;
    const payload = JSON.parse(atob(tokenParts[1]));
    return payload.exp > Date.now() / 1000;
  };

  const setTokens = (accessToken, refreshToken) => {
    if (typeof accessToken !== "string" || typeof refreshToken !== "string") {
      console.error("Invalid token types:", { accessToken, refreshToken });
      throw new Error("Invalid token data");
    }
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    setAuthToken(accessToken);
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
  };

  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      console.error("No refresh token found in localStorage");
      throw new Error("No refresh token found");
    }
    try {
      const response = await api.post("/auth/refresh", { refreshToken });
      const { access_token, refresh_token } = response.data;
      localStorage.setItem("accessToken", access_token);
      localStorage.setItem("refreshToken", refresh_token);
      setAuthToken(access_token);
      return { newAccessToken: access_token, newRefreshToken: refresh_token };
    } catch (error) {
      console.error("Failed to refresh token", error);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      throw error;
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      const storedAccessToken = localStorage.getItem("accessToken");
      const storedRefreshToken = localStorage.getItem("refreshToken");
      console.log("Stored tokens:", {
        accessToken: storedAccessToken ? 'exists' : 'missing',
        refreshToken: storedRefreshToken ? 'exists' : 'missing',
      });
      if (storedAccessToken && storedRefreshToken) {
        setAccessToken(storedAccessToken);
        setRefreshToken(storedRefreshToken);
        setAuthToken(storedAccessToken);
        try {
          const userData = await fetchUser();
          console.log("User data fetched:", userData);
        } catch (error) {
          console.error("Error fetching user data:", error);
          logout();
        }
      }
      setIsAuthChecked(true);
    };
  
    checkAuth();
  }, []);

  const fetchUser = async () => {
    try {
      const response = await api.get("/auth/profile");
      setUser({
        ...response.data,
        role: response.data.role
      });
      setActiveBus(response.data.activeBus);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch user", error);
      if (error.response && error.response.status === 401) {
        try {
          await refreshAccessToken();
          const retryResponse = await api.get("/auth/profile");
          setUser(retryResponse.data.user);
          setActiveBus(retryResponse.data.activeBus);
          console.log("Retry fetch user response:", retryResponse.data);
        } catch (refreshError) {
          console.error("Failed to refresh token and fetch user", refreshError);
          logout();
        }
      } else {
        logout();
      }
    }
  };

  const login = async (email, password) => {
    try {
      const response = await api.post("/auth/login", { email, password });
      console.log("Login response:", response.data);

      console.log('Login response received:', {
        status: response.status,
        data: response.data
      });

      const { access_token, refresh_token, user, activeBus } = response.data;
      if (!access_token || !refresh_token) {
        throw new Error("Invalid token data received from server");
      }
      setTokens(access_token, refresh_token);
      setUser(user);
      setActiveBus(activeBus);
      return response.data;
    } catch (error) {
      console.error("Login failed", error.response ? error.response.data : error.message);
      if (error.response) {
        if (error.response.status === 403 || (error.response.status === 401 && error.response.data.message.includes("אינו פעיל"))) {
          throw new Error("חשבון המשתמש אינו פעיל. אנא צור קשר עם מנהל המערכת.");
        } else if (error.response.status === 401) {
          throw new Error("פרטי התחברות שגויים");
        } else {
          throw new Error(error.response.data.message || "שגיאה בהתחברות. אנא נסה שוב מאוחר יותר.");
        }
      } else {
        throw new Error("שגיאה בהתחברות. אנא בדוק את החיבור לאינטרנט ונסה שוב.");
      }
    }
  };

  const register = async (userData) => {
    try {
      const response = await api.post("/auth/register", userData);
      console.log("Register response:", response.data);
  
      // בדיקה אם המשתמש כבר קיים (שגיאה 409) או כל שגיאה אחרת
      if (!response.data.success) {
        return {
          success: false,
          message: response.data.message || "שגיאה בתהליך ההרשמה",
          statusCode: response.status
        };
      }
  
      const { access_token, refresh_token } = response.data;
      
      // אם אין טוקן גישה או ריענון, נחזיר את ההודעה מהשרת ללא זריקת שגיאה
      if (!access_token || !refresh_token) {
        return {
          success: false,
          message: response.data.message || "לא התקבלו טוקנים תקינים מהשרת",
          statusCode: response.status
        };
      }
  
      // הגדרת הטוקנים אם הם קיימים
      setTokens(access_token, refresh_token);
      await fetchUser();
      
      // החזרת תוצאת הרשמה מוצלחת
      return response.data;
  
    } catch (error) {
      console.error("Registration failed", error);
      // החזרת הודעת שגיאה במקרה של כשל תקשורת או שגיאה בשרת
      return {
        success: false,
        message: error.response?.data?.message || "שגיאה בתהליך ההרשמה",
        statusCode: error.response?.status || 500
      };
    }
  };
  
  
  

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setAuthToken(null);
    setAccessToken(null);
    setRefreshToken(null);
    setUser(null);
    setActiveBus(null);
  };

  const updateActiveBus = async (busId) => {
    try {
      console.log('Updating active bus with ID:', busId);
      const response = await api.post("/buses/set-active", { busId });
      console.log('Set active bus response:', response.data);
      if (response.data) {
        setActiveBus(response.data);
        setUser(prevUser => {
          console.log('Updating user with new active bus:', { ...prevUser, activeBus: response.data });
          return { ...prevUser, activeBus: response.data };
        });
      } else {
        console.error('Set active bus response is empty');
      }
    } catch (error) {
      console.error("Failed to update active bus:", error);
      throw error;
    }
  };

  const clearActiveBus = async () => {
    try {
      await api.post("/buses/clear-active");
      setActiveBus(null);
    } catch (error) {
      console.error("Failed to clear active bus:", error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        register,
        logout,
        refreshAccessToken,
        isAuthChecked,
        api,
        activeBus,
        setActiveBus,
        updateActiveBus,
        clearActiveBus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};