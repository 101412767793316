import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const BackButton = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <IconButton
      onClick={handleGoBack}
      sx={{
        color: 'black',
        position: 'fixed',
        top: 74,  // 10px מתחת לגובה הרגיל של AppBar
        left: 16,
        zIndex: 1100,
      }}
    >
      <ArrowForwardIcon />
    </IconButton>
  );
};

export default BackButton;