import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../auth/useAuth';
import { Alert, Button, Typography, Box, TextField, InputAdornment, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import debounce from 'lodash/debounce';
import AdminLayout from '../components/AdminLayout';

const BusListUser = () => {
  const { api, user, activeBus } = useAuth();
  const [buses, setBuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [totalRows, setTotalRows] = useState(0);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [busToDelete, setBusToDelete] = useState(null);

  const fetchBuses = useCallback(async (search, page, pageSize) => {
    try {
      setLoading(true);
      const response = await api.get('/buses/managed/' + user.id, {
        params: {
          search,
          page: page + 1,
          limit: pageSize,
          activeOnly: true,
        },
      });
      setBuses(response.data.data);
      setTotalRows(response.data.pagination.totalItems);
    } catch (error) {
      console.error('Failed to fetch buses', error);
    } finally {
      setLoading(false);
    }
  }, [api, user.id]);

  const debouncedFetchBuses = useCallback(
    debounce((search, page, pageSize) => fetchBuses(search, page, pageSize), 300),
    [fetchBuses]
  );

  useEffect(() => {
    debouncedFetchBuses(searchTerm, paginationModel.page, paginationModel.pageSize);
  }, [debouncedFetchBuses, searchTerm, paginationModel]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPaginationModel(prev => ({ ...prev, page: 0 }));
  };

  const handleDeleteBus = (bus) => {
    setBusToDelete(bus);
    setOpenDialog(true);
  };

  const confirmDeleteBus = async () => {
    try {
      await api.delete(`/buses/${busToDelete.id}`);
      setOpenDialog(false);
      // רענון רשימת האוטובוסים לאחר המחיקה
      debouncedFetchBuses(searchTerm, paginationModel.page, paginationModel.pageSize);
    } catch (error) {
      console.error('Failed to delete bus', error);
      // כאן אתה יכול להוסיף הודעת שגיאה למשתמש
    }
  };

  const columns = [
    { field: 'busNumber', headerName: 'מספר אוטובוס', width: 150 },
    {
      field: 'actions',
      headerName: 'פעולות',
      width: 300,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => navigate(`/open-bus/${params.row.id}`)}
            sx={{ mr: 1 }}
          >
            ערוך אוטובוס
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => navigate(`/validate-passengers/${params.row.id}`)}
            sx={{ mr: 1 }}
          >
            הצג אוטובוס
          </Button>
          {params.row.validationCount === 0 && (
            <Button
              variant="contained"
              color="error"
              size="small"
              startIcon={<DeleteIcon />}
              onClick={() => handleDeleteBus(params.row)}
            >
              מחק
            </Button>
          )}
        </Box>
      ),
    },
    { field: 'destination', headerName: 'יעד', width: 200 },
    { field: 'date', headerName: 'תאריך', width: 150 },
    { field: 'time', headerName: 'שעה', width: 100 },

  ];

  return (
    <AdminLayout>
      <Typography variant="h4" gutterBottom align="center">
        רשימת האוטובוסים שלי
      </Typography>
      
      {activeBus && (
        <Alert severity="info" sx={{ mb: 2 }} action={
          <Button color="inherit" size="small" onClick={() => navigate(`/validate-passengers/${activeBus.id}`)}>
            חזור לאוטובוס פעיל
          </Button>
        }>
          יש לך אוטובוס פעיל (מספר {activeBus.busNumber})
        </Alert>
      )}
      
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="חיפוש..."
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={buses}
          columns={columns}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          rowCount={totalRows}
          pageSizeOptions={[5, 10, 25]}
          checkboxSelection
          disableSelectionOnClick
          loading={loading}
        />
      </Box>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"אישור מחיקת אוטובוס"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            האם אתה בטוח שברצונך למחוק את האוטובוס מספר {busToDelete?.busNumber}? .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            ביטול
          </Button>
          <Button onClick={confirmDeleteBus} color="error" autoFocus>
            מחק
          </Button>
        </DialogActions>
      </Dialog>
    </AdminLayout>
  );
};

export default BusListUser;