import React, { useState, useEffect } from 'react';
import { Typography, Button, Box } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';
import AdminLayout from '../components/AdminLayout';

const UserList = () => {
  const { api } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await api.get('/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Failed to fetch users', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [api]);

  const getRoleInHebrew = (role: string) => {
    switch (role) {
      case 'manager':
        return 'מנהל';
      case 'bus_coordinator':
        return 'סדרן אוטובוסים';
      default:
        return role;
    }
  };

  const getStatusInHebrew = (status: string) => {
    switch (status) {
      case 'active':
        return 'פעיל';
      case 'inactive':
        return 'לא פעיל';
      case 'pending':
        return 'ממתין לאישור';
      default:
        return status;
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    { field: 'lastName', headerName: 'שם משפחה', flex: 1 },
    { field: 'firstName', headerName: 'שם פרטי', flex: 1 },
    { field: 'email', headerName: 'אימייל', flex: 1.5 },
    { field: 'phone', headerName: 'טלפון', flex: 1 },
    { 
      field: 'role', 
      headerName: 'תפקיד', 
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return getRoleInHebrew(params.value);
      }
    },
    { 
      field: 'status', 
      headerName: 'סטטוס', 
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return getStatusInHebrew(params.value);
      }
    },
    {
      field: 'actions',
      headerName: 'פעולות',
      flex: 1,
      renderCell: (params) => (
        <Button
          component={Link}
          to={`/admin/users/edit/${params.row.id}`}
          variant="contained"
          color="primary"
          size="small"
        >
          ערוך
        </Button>
      ),
    },
  ];

  return (
    <AdminLayout>
      <Box sx={{ mt: '64px' }}>
        <Typography variant="h4" gutterBottom align="center" mt={0}>
          ניהול משתמשים
        </Typography>

        {/* עטיפת הטבלה ב-Box כדי לאפשר גלילה אופקית */}
        <Box sx={{ width: '100%', overflowX: 'auto' }}>
          <Box sx={{ minWidth: 1000 }}> {/* הגדרת מינימום רוחב */}
            <DataGrid
              rows={users}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              disableSelectionOnClick
              disableColumnMenu
              loading={loading}
              autoHeight
              sx={{
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cell:focus-within': {
                  outline: 'none',
                },
                '& .MuiDataGrid-virtualScroller': {
                  overflow: 'hidden',
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default UserList;
